<template>
  <div id="snackbar" :class="locationClass">Some text some message..</div>
</template>


<script>

  let snackbar;

  function setDisplayDuration(duration) {
    let delay = duration + 's';
    snackbar.style.setProperty('--delay', delay)
  }

  function setOffsetFromEdge(offset) {
    let dist = offset + 'px';
    snackbar.style.setProperty('--movement-dist', dist)
  }

  function snackbarMessage(message, duration) {
    snackbar.textContent = message;
    snackbar.classList.add("show");
    setTimeout(() => snackbar.classList.remove("show"), (duration + 1.1) * 1000);
  }

  export default {
    props: {
      message: String,
      isTop: Boolean,
      isLongDuration: Boolean,
      offsetFromEdgePx: Number,
    },
    watch: {
      message: function(val) {
        if (val) snackbarMessage(val, this.displayDuration);
      },
      displayDuration: function(duration) {
        setDisplayDuration(duration);
      },
      offsetFromEdgePx: function(offset) {
        setOffsetFromEdge(offset);
      },
    },
    computed: {
      locationClass: function() {
        return this.isTop ? "top" : "bottom";
      },
      displayDuration: function() {
        return this.isLongDuration ? 4 : 2.5;
      }
    },
    mounted() {
        snackbar = document.getElementById("snackbar");
        setDisplayDuration(this.displayDuration);
        setOffsetFromEdge(this.offsetFromEdgePx);
    }
  }
</script>

<style>
#snackbar {
  /* these variables are manipulated in js */
  --movement-dist: 30px;
  --delay: 2.5s;
  --fade-duration: 0.5s;
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 40%;
  font-size: 17px;
}
.bottom {
  bottom: var(--movement-dist);
}
.top {
  top: var(--movement-dist);
}
#snackbar.show {
  visibility: visible;
}
#snackbar.bottom.show {
  animation: bottom-fadein var(--fade-duration), bottom-fadeout var(--fade-duration) var(--delay) forwards;
}
#snackbar.top.show {
  animation: top-fadein var(--fade-duration), top-fadeout var(--fade-duration) var(--delay) forwards;
}

@keyframes top-fadein {
  0% {top: 0; opacity: 0;}
  100% {top: var(--movement-dist); opacity: 1;}
}

@keyframes top-fadeout {
  0% {top: var(--movement-dist); opacity: 1;}
  100% {top: 0; opacity: 0;}
}

@keyframes bottom-fadein {
  0% {bottom: 0; opacity: 0;}
  100% {bottom: var(--movement-dist); opacity: 1;}
}

@keyframes bottom-fadeout {
  0% {bottom: var(--movement-dist); opacity: 1;}
  100% {bottom: 0; opacity: 0;}
}
</style>
