<template>
  <div>
    <div class="load-controls" v-show="!showDocumentControls">
      <file-selector
        mimeType="application/pdf"
        typeLabel="pdf"
        @file_selected="val => onFileSelected(val)"
      />
    </div>
    <div v-show="showDocumentControls" class="document-controls">
      <div class="drawing-name-controls-container">
        <div class="drawing-name-controls">
          <div class="control-item">
            <label for="drawing-name-prefix">Drawing name prefix:</label>
            <input type="text" id="drawing-name-prefix" v-model="drawingNamePrefix" />
          </div>
          <div class="control-item">
            <label for="drawing-name-padding">Number padding:</label>
            <input type="number" id="drawing-name-padding" v-model="drawingNumberLength" />
          </div>
          <div class="control-item">
            <label for="drawing-name-suffix">Drawing name suffix:</label>
            <input type="text" id="drawing-name-suffix" v-model="drawingNameSuffix" />
          </div>
          <div class="btn-group">
            <a class="btn btn-success" :disabled="!finishedLoading || !haveSelectedDrawings" :title="renameButtonTitle" @click.stop="updateSelectedDrawingLabels(replaceDrawingLabel)">{{renameButtonLabel}}</a>
          </div>
        </div>
        <div class="drawing-name-controls">
          <div class="control-item">
            <label for="label-selector">Update:</label>
            <select id="label-selector" v-model="drawingLabelToEdit">
              <option value="name">Names</option>
              <option value="version">Versions</option>
            </select>
          </div>
          <div class="control-item">
            <label for="name-search-for">Find this exact text:</label>
            <input type="text" id="name-search-for" v-model="drawingLabelSearchFor" />
          </div>
          <div class="control-item">
            <label for="name-replace-with">Replace with:</label>
            <input type="text" id="name-replace-with" v-model="drawingLabelReplaceWith" />
          </div>
          <div class="btn-group">
            <a class="btn btn-success" :disabled="!finishedLoading || !haveSelectedDrawings" :title="replaceButtonTitle" @click.stop="updateSelectedDrawingLabels(updateDrawingLabel, isEditingVersion)">{{replaceButtonLabel}}</a>
          </div>
        </div>
      </div>

      <div class="page-controls">
        <div>
          <button class="btn" :disabled="!finishedLoading" @click="selectAll" title="Select all drawings">Select all</button>
          <button class="btn" :disabled="!finishedLoading" @click="invertSelection" title="Invert drawing selection">Invert selection</button>
          <select v-model="thumbnailSizeOption">
            <option :value="option.key" v-for="option in sizeOptions" :key="option.key">{{ option.value }}</option>
          </select>
          <button class="btn" :disabled="!finishedLoading" @click="onResetDrawingNames" title="Reset ALL drawing names back to their original default">Reset all names</button>
        </div>
        <div>
          <button class="btn btn-primary" :disabled="!allowUse" @click.prevent="useSelectedPages" :title="usePrompt">Create drawings</button>
          <a class="btn" :href="cancelUrl()">Cancel</a>
        </div>
      </div>
      <div id="loading_progress_bar" 
        :aria-valuenow="loadedPercent" 
        aria-valuemin="0" 
        aria-valuemax="100"
        :style="{width: loadedPercent + '%'}">
      </div>
    </div>
    <pdf
      id="pdf-document"
      v-bind="{selectedPdfFile, selectedPages}"
      :showDetails="true"
      :drawingDetails="drawingDetails"
      :pageSizeOption="thumbnailSizeOption"
      :resetNames="resetDrawingNames"
      @pageClicked="val => onPageClicked(val)"
      @update:drawing="val => onUpdateDrawing(val)"
      @loading_percent="val => loadedPercent = val"
    />
    <progress-reporter
      :allowCancel="true"
      :cancelHoverText="'Stop loading drawings.'"
      :actionLabel="'Loading drawing'"
      :totalProgress="totalProgress"
      :currentProgress="progressCount"
      :failMessages="failMessages"
      :finished="finishedSaving"
      @cancel="cancel"
      v-show="isWorking"
    />
  </div>  
</template>

<script>
  import _ from 'lodash'
  import { detailSizeOptions as pageSizeOptions } from './drawing_editor_sections/drawing_pdf_document.vue'
  import pdf_view from './drawing_editor_sections/drawing_pdf_document.vue'
  import { PdfImageGenerator } from "../lib/pdf_image_generator"
  import { AssetUploader } from "../lib/asset_uploader"
  import progress_reporter from './utils/progress_reporter_overlay.vue'
  import file_selector from './utils/combo_file_selector.vue'

  export default {
    components: {
      pdf: pdf_view,
      progressReporter: progress_reporter,
      fileSelector: file_selector,
    },
    data: function() {
      return  {
        selectedPdfFile: null,
        selectedPages: [],
        drawingDetails: {},
        drawingNamePrefix: "",
        drawingNameSuffix: "",
        drawingNumberLength: 3,
        drawingLabelSearchFor: null,
        drawingLabelReplaceWith: "",
        drawingLabelToEdit: "name",
        resetDrawingNames: false,
        drawingsToLoad: null,
        progressCount: 0,
        failMessages: [],
        finishedSaving: false,
        sizeOptions: pageSizeOptions,
        thumbnailSizeOption: pageSizeOptions[0].key,
        loadedPercent: 0,
      }
    },
    computed: {
      showDocumentControls: function () {
        return this.selectedPdfFile;
      },
      finishedLoading: function() {
        return this.loadedPercent == 100;
      },
      haveSelectedDrawings: function() {
        return this.selectedDrawings.length > 0;
      },
      selectedDrawings: function() {
        return _.filter(this.drawingDetails, d => this.selectedPages.includes(d.page_number));
      },
      allowUse: function () {
        let selected = this.selectedDrawings;
        return selected.length > 0
          && _.findIndex(selected, d => _.isEmpty(d.drawing_name)) < 0
          && _.uniqBy(selected, d => d.drawing_name).length == selected.length
      },
      usePrompt: function () {
        return this.selectedPages.length == 0 ? this.selectDrawingPrompt
          : this.allowUse ? "Create the selected drawings"
          : "Selected drawings must have unique names before they can be created";
      },
      isWorking: function() {
        return this.drawingsToLoad;
      },
      totalProgress: function() {
        return this.drawingsToLoad?.length ?? 0;
      },
      isEditingVersion: function() {
        return this.drawingLabelToEdit == 'version';
      },
      selectDrawingPrompt: function () {
        return "First select a drawing";
      },
      renameButtonLabel: function() {
        return "Rename selected drawings";
      },
      renameButtonTitle: function() {
        return this.haveSelectedDrawings ? this.renameButtonLabel : this.selectDrawingPrompt;
      },
      replaceButtonLabel: function() {
        return 'Update selected drawings';
      },
      replaceButtonTitle: function() {
        return this.haveSelectedDrawings ? this.replaceButtonLabel : this.selectDrawingPrompt;
      },
    },
    methods: {
      onFileSelected: function (file) {
        if (null == file || file.type != 'application/pdf') {
          return;
        }
        this.loadedPercent = 0;
        this.selectedPdfFile = file;
        this.selectedPages = [];
        this.drawingDetails = {};
      },
      onPageClicked: function(page) {
        let localPages = this.selectedPages.slice();
        let index = _.findLastIndex(localPages, p => p <= page);
        if (-1 == index) {
          localPages.unshift(page);
        } else if (localPages[index] == page) {
          localPages.splice(index, 1);
        } else {
          localPages.splice(index + 1, 0, page);
        }
        this.selectedPages = localPages;
      },
      onUpdateDrawing: function(drawing) {
        let drawingDetails = _.clone(this.drawingDetails);
        drawingDetails[drawing.page_number] = drawing;
        this.drawingDetails = drawingDetails;
      },
      selectAll: function () {
        let pages = _.sortBy(_.map(this.drawingDetails, d => d.page_number));
        this.selectedPages = pages;
      },
      invertSelection: function () {
        let pages = _.sortBy(_.map(this.drawingDetails, d => d.page_number));
        let inverted = _.difference(pages, this.selectedPages);
        this.selectedPages = inverted;
      },
      onResetDrawingNames: function () {
        this.resetDrawingNames = true;
        this.$nextTick(() => this.resetDrawingNames = false)
      },
      updateSelectedDrawingLabels: function(nameCalculator, isVersion) {
        let orderedPages = _.sortBy(this.selectedPages);
        let size = orderedPages.length;
        for (let i = 0; i < size; i++) {
          let pageNumber = orderedPages[i];
          let drawing = this.drawingDetails[pageNumber];
          let clone = drawing ? _.clone(drawing) : {page_number: pageNumber};
          if (!!isVersion) {
            clone.version_name = nameCalculator(clone.version_name, i);
          } else {
            clone.drawing_name = nameCalculator(clone.drawing_name, i);
          }
          this.onUpdateDrawing(clone);
        }
      },
      replaceDrawingLabel: function(existingName, i) {
        return this.drawingNamePrefix + String(i + 1).padStart(this.drawingNumberLength, '0') + this.drawingNameSuffix;
      },
      updateDrawingLabel: function(existing) {
        if (!existing && !this.drawingLabelSearchFor) {
          return this.drawingLabelReplaceWith;
        } else {
          return existing.replace(this.drawingLabelSearchFor, this.drawingLabelReplaceWith);
        }
      },
      useSelectedPages: async function() {
        this.reset();
        this.finished = false;
        let loader = await PdfImageGenerator(this.selectedPdfFile);
        let drawings = this.selectedDrawings;
        this.drawingsToLoad = this.selectedDrawings;
        let size = drawings.length;
        for (let i = 0; i < size; i++) {
          let drawing = drawings[i];
            let image = {blob: null};
          try {
            image = await loader.makeJpegImage(drawing.page_number, drawing.rotation);
          } catch {}
          await this.saveDrawing(drawing, image);
          if (!this.isWorking) {
            return; // likely user has cancelled
          }
          this.progressCount++;
        }
        this.drawingsToLoad = null;
        if (this.failMessages.length == 0) {
          this.return();
        }
        this.finished = true;
      },
      cancel: function() {
        let wasWorking = this.isWorking;
        this.reset();
        if (!wasWorking) {
          this.return();
        }
      },
      return: function() {
        window.location = this.cancelUrl();
      },
      reset: function() {
        this.drawingsToLoad = null;
        this.progressCount = 0;
        this.failMessages = [];
      },
      
      saveDrawing: async function(drawing, image) {
        let failed = false;
        let vm = this;
        if (!vm.isWorking) {
          return; // likely user has cancelled
        }
        if (drawing) {
          if (!image.blob) {
            fail("Unexpected error loading PDF page for drawing '" + drawing.drawing_name + "'.");
            return;
          }

          let drawingId = await new Promise((resolve, reject) => {
            let uploader = AssetUploader(
              assetId => resolve(assetId), 
              message => reject(message),
              null // message => vm.informationText = message
            );
            uploader.uploadJpeg(image.blob)
          }).catch((message) => {
            fail("Problem uploading drawing '" + drawing.drawing_name + "'. " + message);
          });

          if (!failed) {
            let newDrawing = {
              drawing_set_id: drawingSetId(),
              name: drawing.drawing_name,
              assets: JSON.stringify([{
                current: true,
                version: drawing.version_name,
                top_left: [0,0],
                bottom_right: [1,1],
                original_asset_id: drawingId,
                view_id: "",
                asset_file_size: image.blob.size,
                view_file_size: "",
                aspect_ratio: image.width / image.height,
              }]),
            }

            await vm.$http.post(drawingUrl(), {drawing: newDrawing})
              .catch((result) => {
                let message = '';
                try {
                  message = result.body.error;
                } catch {}
                fail("Could not save drawing '" + drawing.drawing_name + "'. " + message); 
              });
          }

          function drawingUrl() {
            return window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')) + "/drawings/import";
          };
          function drawingSetId() {
            let endPart = window.location.pathname.substring(window.location.pathname.indexOf('/drawing_sets/') + 14);
            return endPart.substring(0, endPart.indexOf('/'))
          };
        }
        function fail(message) {
          vm.failMessages.push(message);
          failed = true;
        };
      },

      cancelUrl: function() {
        return window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/'));
      },
    },
  }

</script>

<style scoped>
  .load-controls {
    width: 20%;
    height: 80px;
  }
  .page-controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    gap: 8px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .page-controls * {
    margin-bottom: 0;
  }
  .control-item {
    display: flex;
    flex-direction: column;
  }
  .control-item input, .control-item select {
    margin-bottom: 0;
  }
  .control-item label {
    padding-left: 6px;
  }
  .control-item #label-selector {
    width: auto;
    flex-grow: 0;
  }
  .drawing-name-controls-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .drawing-name-controls {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 8px;
    outline: 2px solid #ccc !important;
    border-radius: 4px;
    padding: 8px;
  }
  .drawing-name-controls input[type=number] {
    width: 8em;
  }

  #loading_progress_bar {
    width: 0%;
    height: 2px;
    background-color: #0099FF;
  }
</style>
