<template>
  <div>
    <div class="pdf-document-container">
      <div v-for="pageNumber in pages" :key="pageNumber" 
        :class="['pdf-document-page-container', selectedPages.includes(pageNumber) ? 'selected-page' : '']">
        <pdfPage
          v-bind="{pageNumber, pdfLoader, showDetails, pageSizeOption}"
          :drawingDetails="drawingDetails[pageNumber]"
          :pageLabelOptions="pageOutlines[pageNumber-1]"
          :resetName="resetNames"
          @loaded="onPageLoaded(pageNumber)"
          @update:drawing="val => onUpdateDrawing(pageNumber, val)"
          @click="$emit('pageClicked', pageNumber)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import pdfPage from './drawing_pdf_page.vue';

  export { sizeOptions, detailSizeOptions } from './drawing_pdf_page.vue'

  var pdfjs = require('pdfjs-dist/es5/build/pdf.js');
  var PdfjsWorker = require('worker-loader!pdfjs-dist/es5/build/pdf.worker.js');
  pdfjs.GlobalWorkerOptions.workerPort = new PdfjsWorker();

  export default {
    components: {
      pdfPage: pdfPage
    },
    props: {
      selectedPdfFile: File,
      showDetails: Boolean,
      selectedPages: Array,
      drawingDetails: Object,
      pageSizeOption: Number,
      resetNames: Boolean,
    },
    data: function () {
      return {
        pdfLoader: null,
        pages: [],
        loadedPages: [],
        loadProgress: 0,
        pageOutlines: [],
      }
    },
    watch: {
      selectedPdfFile: function(pdfFile) {
        let vm = this;
        vm.loadedPages = [];
        vm.loadProgress = 0;
        vm.pdfLoader = null;
        if (!_.isNil(pdfFile)) {
          let fileReader = new FileReader();
          fileReader.onload = () => {
            let typedarray = new Uint8Array(fileReader.result);
            vm.pdfLoader = pdfjs.getDocument(typedarray);
          };
          fileReader.readAsArrayBuffer(pdfFile);
        }
      },
      pdfLoader: async function(loader) {
        if (loader != null) {
          let pdf = await loader.promise;
          this.pageOutlines = await this.extractPageOutlineLabels(pdf);
          this.pages = _.isNumber(pdf.numPages) 
            ? _.range(1, pdf.numPages + 1)
            : [];
        }
      },
      loadProgress: function(percent) {
        this.$emit("loading_percent", percent);
      },
    },
    methods: {
      onPageLoaded: function (pageNumber) {
        if (!this.loadedPages.find(p => p == pageNumber)) {
          this.loadedPages.push(pageNumber);
          this.loadProgress = this.loadedPages.length / this.pages.length * 100;
        }
      },
      onUpdateDrawing: function(pageNumber, drawing) {
        drawing.page_number = pageNumber;
        this.$emit("update:drawing", drawing)
      },
      extractPageOutlineLabels: async function(pdfDoc) {
        let pdfOutline = await pdfDoc.getOutline();
        let lookup = Array.apply(null, Array(pdfDoc.numPages)).map(() => []);
        await extractLevel(lookup, pdfDoc, pdfOutline);
        return lookup;

        async function extractLevel(lookup, pdfDoc, items) {
          let length = items?.length ?? 0;
          for (let i = 0; i < length; i++) {
            let item = items[i];
            try {
              let pageIndex = await pdfDoc.getPageIndex(item.dest[0]);
              lookup[pageIndex].push(item.title);
            } catch (ex) { }
            await extractLevel(lookup, pdfDoc, item.items);
          }
        }
      },
    },
  }
</script>
<style scoped>
  .pdf-document-container {
    display: flex;
    flex-flow: row wrap;
  }
  .pdf-document-page-container {
    flex: 0 1 auto;
    margin: 7px;
    overflow-y: auto;
  }
  .pdf-document-page {
    min-width:120px;
  }
  .selected-page {
    outline: 4px solid #007E3D !important;
  }
</style>